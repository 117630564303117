/* eslint-disable*/
import React from "react";
import * as s from "../styles/ModalStyle";

const FanfanTVModal = ({ onClose }) => {
  return (
    <s.ModalBackground>
      <s.ModalContainer>
        <s.ModalTitleText>zzuniHomelab</s.ModalTitleText>
        <s.InfoText>해당 프로젝트는 미완료 상태로, 아직 진행중인 프로젝트입니다. 하단에 서술된 내용이 일부 부정확할 수 있습니다.</s.InfoText>
        <s.ModalCloseButton onClick={onClose}>X</s.ModalCloseButton>
        <s.ModalDescText>
          zzuniHomelab은 가정에서 서버 & 네트워크를 구축하는 프로젝트로, 시작은 2023년 1월이었으나 본격적으로 프로젝트화 된 것은 2023년 7월부터입니다.
          <br />
          zzuniHomelab은 LACP, LAGG와 같은 네트워크 기술로 구성된 고성능 10Gbe+ 네트워크와 엔터프라이즈급 서버, NAS를 구축 및 운영중이며 Proxmox를 통한 가상화 기술이나 TrueNAS, DSM과 같은 NAS 소프트웨어를 통해 다양한 분야에서 다양한 서비스를 제공하고 있습니다.
          <br />
          현재는 95Gbit/s 이상의 업링크가 연결되어 있으며, 네트워크 관련 지식을 폭넓게 습득하고 있습니다. FANFANTV, zzuniMirror 또한 zzuniHomelab에서 운영중이거나 운영했던 적이 있습니다.
        </s.ModalDescText>
        <s.ModalSubTitleText>기술 스택</s.ModalSubTitleText>
        <s.ModalTechStackContainer></s.ModalTechStackContainer>
        <s.ModalSubTitleText>참조</s.ModalSubTitleText>
        <s.ModalReferenceContainer>
          <s.ModalReferencezzuniHomelabImage />
        </s.ModalReferenceContainer>
      </s.ModalContainer>
    </s.ModalBackground>
  );
};

export default FanfanTVModal;
