/* eslint-disable*/
import styled from "styled-components";
import Logopng from "../assets/images/logo.png";
import Globesvg from "../assets/images/globe.svg";
import Statussvg from "../assets/images/status.svg";
import Increasingsvg from "../assets/images/increasing.svg";

export const LogoBox = styled.div`
  width: 150px;
  height: 100%;
  background-image: url(${Logopng});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const IncreaseImageBox = styled.div`
  background-image: url(${Increasingsvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 75%;
  height: 75%;
`;

export const UtilityBox = styled.div`
  width: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 890px) {
    justify-content: flex-end;
  }
`;

export const Globe = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(${Globesvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const Status = styled.div`
  width: 32px;
  height: 28px;
  background-image: url(${Statussvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const Navigator = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavigatorText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const MainNavbar = styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  height: 50px;
  padding: 0 4rem;

  @media (max-width: 890px) {
    padding: 0 2rem;

    ${Navigator} {
      display: none;
    }

    ${NavigatorText} {
      display: block;
      text-align: center;
      padding: 10px;
      width: 100%;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 700px;
  z-index: 1;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 542px) {
    height: 500px;
  }
`;

export const SubContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 4rem;
  background-color: #f7f7f7;

  @media (max-width: 542px) {
    padding: 10px 4rem;
  }
`;

export const SubContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 4rem;
  background-color: #f7f7f7;

  @media (max-width: 542px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 4rem;
  }
`;

export const SubContainer3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 4rem;
  background-color: #f7f7f7;
  padding-bottom: 25px;

  @media (max-width: 542px) {
    padding: 10px 4rem;
  }
`;

export const SubContainerSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f1f1f1;
  width: 100%;
  height: 400px;
  border-radius: 20px;
  margin-top: 30px;
  padding: 0 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 542px) {
    height: 350px;
  }
`;

export const SubContainerSectioneng = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f1f1f1;
  width: 100%;
  height: 400px;
  border-radius: 20px;
  margin-top: 30px;
  padding: 0 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 918px) {
    padding-bottom: 15px;
  }

  @media (max-width: 846px) {
    padding-bottom: 25px;
  }

  @media (max-width: 824px) {
    padding-bottom: 0px;
  }

  @media (max-width: 770px) {
    padding-bottom: 20px;
  }

  @media (max-width: 662px) {
    padding-bottom: 50px;
  }

  @media (max-width: 636px) {
    padding-bottom: 60px;
  }

  @media (max-width: 616px) {
    padding-bottom: 0px;
  }

  @media (max-width: 560px) {
    padding-bottom: 20px;
  }

  @media (max-width: 542px) {
    height: 350px;
  }
`;

export const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
  flex: 1;

  @media (max-width: 1458px) {
    grid-template-columns: repeat(4, minmax(200px, 1fr));
  }

  @media (max-width: 980px) {
    grid-template-columns: repeat(3, minmax(150px, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }

  @media (max-width: 542px) {
    grid-template-columns: 1fr;
  }
`;

export const ProjectCardContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: 130px;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
  }

  @media (max-width: 542px) {
    width: 200px;
  }
`;

export const ProjectTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #333333;
`;

export const ProjectDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #9ca3af;
  margin-top: 10px;

  @media (max-width: 558px) {
    font-size: 12px;
  }
`;

export const ProjectDescriptionlongeng = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #9ca3af;
  margin-top: 10px;

  @media (max-width: 1630px) {
    font-size: 12px;
  }

  @media (max-width: 1186px) {
    font-size: 10px;
  }
`;

export const SubContainerSectionImageBox = styled.div`
  width: 50%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  @media (max-width: 542px) {
    display: none;
  }
`;

export const SubContainerSectionTextBox = styled.div`
  width: 50%;
  height: 350px;
  margin-top: 25px;
  margin-bottom: 0px;

  @media (max-width: 542px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0px;
  }
`;

export const SubContainerSectionTextBox2 = styled.div`
  width: 75%;
  margin-top: 25px;
  margin-bottom: 0px;

  @media (max-width: 542px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SubContainerSectionTitle1 = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-top: 5px;

  @media (max-width: 542px) {
    font-size: 18px;
    padding: 0;
    margin-top: 0px;
  }
`;

export const SubContainer2SectionTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-top: 5px;

  @media (max-width: 542px) {
    font-size: 20px;
    padding: 0;
  }
`;

export const SubContainerSectionTitle2 = styled.p`
  font-size: 45px;
  font-weight: 600;
  color: #333333;
  margin-top: 20px;

  @media (max-width: 1064px) {
    font-size: 40px;
  }

  @media (max-width: 960px) {
    font-size: 35px;
  }

  @media (max-width: 856px) {
    font-size: 30px;
  }

  @media (max-width: 752px) {
    font-size: 25px;
  }

  @media (max-width: 648px) {
    font-size: 20px;
  }

  @media (max-width: 542px) {
    font-size: 28px;
    padding: 0;
  }

  @media (max-width: 420px) {
    font-size: 24px;
  }

  @media (max-width: 378px) {
    font-size: 20px;
  }

  @media (max-width: 336px) {
    font-size: 18px;
  }
`;

export const SubContainer2SectionTitle2 = styled.p`
  font-size: 45px;
  font-weight: 600;
  color: #333333;
  margin-top: 20px;

  @media (max-width: 1064px) {
    font-size: 40px;
  }

  @media (max-width: 960px) {
    font-size: 35px;
  }

  @media (max-width: 856px) {
    font-size: 30px;
  }

  @media (max-width: 752px) {
    font-size: 25px;
  }

  @media (max-width: 648px) {
    font-size: 25px;
  }

  @media (max-width: 542px) {
    font-size: 28px;
    padding: 0;
  }

  @media (max-width: 474px) {
    font-size: 22px;
  }

  @media (max-width: 420px) {
    font-size: 20px;
  }

  @media (max-width: 378px) {
    font-size: 18px;
  }

  @media (max-width: 351px) {
    font-size: 16px;
  }
`;

export const SubContainerSectionDesc = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #9ca3af;
  margin-top: 25px;
  padding-right: 20px;

  @media (max-width: 823px) {
    font-size: 16px;
  }

  @media (max-width: 615px) {
    font-size: 14px;
    padding-right: 0px;
  }

  @media (max-width: 542px) {
    font-size: 14px;
    padding-right: 0px;
    text-align: center;
  }

  @media (max-width: 368px) {
    font-size: 12px;
    text-align: center;
    padding-right: 0px;
  }
`;

export const SubContainer2SectionDesc = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #9ca3af;
  margin-top: 25px;
  padding-right: 20px;

  @media (max-width: 823px) {
    font-size: 16px;
  }

  @media (max-width: 615px) {
    font-size: 14px;
  }

  @media (max-width: 542px) {
    font-size: 14px;
    text-align: center;
    padding-right: 0px;
  }

  @media (max-width: 368px) {
    font-size: 12px;
    text-align: center;
    padding-right: 0px;
  }
`;

export const SubContainer2SectionDesceng = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #9ca3af;
  margin-top: 25px;
  padding-right: 20px;

  @media (max-width: 823px) {
    font-size: 16px;
  }

  @media (max-width: 615px) {
    font-size: 14px;
  }

  @media (max-width: 542px) {
    font-size: 14px;
    text-align: center;
  }

  @media (max-width: 368px) {
    font-size: 12px;
    text-align: center;
  }
`;

export const MainTitleText = styled.p`
  font-size: 140px;
  font-weight: 700;
  color: white;
  z-index: 2;

  @media (max-width: 734px) {
    font-size: 120px;
  }

  @media (max-width: 648px) {
    font-size: 100px;
  }

  @media (max-width: 542px) {
    font-size: 80px;
  }

  @media (max-width: 436px) {
    font-size: 60px;
  }

  @media (max-width: 332px) {
    font-size: 40px;
  }
`;

export const MainDescText = styled.p`
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
  color: white;
  z-index: 2;

  @media (max-width: 734px) {
    font-size: 18px;
  }

  @media (max-width: 648px) {
    font-size: 16px;
  }

  @media (max-width: 542px) {
    font-size: 14px;
  }

  @media (max-width: 488px) {
    font-size: 12px;
  }

  @media (max-width: 430px) {
    font-size: 10px;
  }

  @media (max-width: 332px) {
    font-size: 8px;
  }
`;

export const MainDescTexteng = styled.p`
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
  color: white;
  z-index: 2;

  @media (max-width: 826px) {
    font-size: 18px;
  }

  @media (max-width: 746px) {
    font-size: 16px;
  }

  @media (max-width: 674px) {
    font-size: 14px;
  }

  @media (max-width: 596px) {
    font-size: 12px;
  }

  @media (max-width: 508px) {
    font-size: 10px;
  }

  @media (max-width: 418px) {
    font-size: 8px;
  }

  @media (max-width: 332px) {
    font-size: 7px;
  }
`;

export const SubContainerTitleText = styled.p`
  font-size: 60px;
  font-weight: 600;
  color: #333333;
`;

export const SubContainerDescText = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: #333333;
`;

export const SubContainerButton = styled.button`
  background-color: #536dfe;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 40px;

  &:hover {
    opacity: 0.5;
  }

  @media (min-width: 1143px) {
    margin-top: 60px;
  }

  @media (max-width: 1142px) {
    margin-top: 60px;
  }

  @media (max-width: 542px) {
    margin-top: 25px;
  }

  @media (max-width: 332px) {
    font-size: 16px;
  }
`;

export const SubContainerButtoneng = styled.button`
  background-color: #536dfe;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 40px;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 1240px) {
    margin-top: 20px;
  }

  @media (max-width: 1142px) {
    margin-top: 20px;
  }

  @media (max-width: 984px) {
    margin-top: 7px;
  }

  @media (max-width: 824px) {
    margin-top: 15px;
  }

  @media (max-width: 560px) {
    font-size: 16px;
  }

  @media (max-width: 542px) {
    margin-top: 25px;
  }

  @media (max-width: 332px) {
    font-size: 16px;
  }

  @media (max-width: 324px) {
    font-size: 14px;
  }

  @media (max-width: 305px) {
    font-size: 12px;
  }
`;

export const GotoProjectButton = styled.button`
  background-color: #536dfe;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 542px) {
    margin-top: 10px;
  }
`;

export const OperationalText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #2fcc66;
  margin-top: 10px;
`;

export const MaintenanceText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #f1c40f;
  margin-top: 10px;
`;

export const OutageText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #e74c3c;
  margin-top: 10px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
`;

export const ContactUsFormBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #536dfe;
  width: 100%;
  height: 200px;
  border-radius: 20px;
  margin-top: 30px;
  padding: 0 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 504px) {
    height: 150px;
  }

  @media (max-width: 364px) {
    height: 120px;
  }
`;

export const ContactUsFormTitleText = styled.p`
  font-size: 50px;
  font-weight: 600;
  color: white;

  @media (max-width: 610px) {
    font-size: 40px;
  }

  @media (max-width: 504px) {
    font-size: 30px;
  }

  @media (max-width: 412px) {
    font-size: 25px;
  }

  @media (max-width: 364px) {
    font-size: 20px;
  }

  @media (max-width: 318px) {
    font-size: 18px;
  }
`;

export const ContactUsFormTitleTexteng = styled.p`
  font-size: 50px;
  font-weight: 600;
  color: white;

  @media (max-width: 754px) {
    font-size: 40px;
  }

  @media (max-width: 620px) {
    font-size: 35px;
  }

  @media (max-width: 560px) {
    font-size: 30px;
  }

  @media (max-width: 502px) {
    font-size: 25px;
  }

  @media (max-width: 438px) {
    font-size: 20px;
  }

  @media (max-width: 378px) {
    font-size: 18px;
  }

  @media (max-width: 350px) {
    font-size: 16px;
  }
`;

export const ContactUsFormDescText = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-top: 5px;

  @media (max-width: 890px) {
    text-align: center;
  }

  @media (max-width: 504px) {
    font-size: 16px;
  }

  @media (max-width: 412px) {
    font-size: 14px;
  }

  @media (max-width: 364px) {
    font-size: 12px;
  }

  @media (max-width: 318px) {
    font-size: 10px;
  }
`;

export const ContactUsFormDescTexteng = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-top: 5px;

  @media (max-width: 1064px) {
    font-size: 16px;
  }

  @media (max-width: 966px) {
    font-size: 14px;
  }

  @media (max-width: 890px) {
    text-align: center;
  }

  @media (max-width: 504px) {
    font-size: 13px;
  }

  @media (max-width: 412px) {
    font-size: 12px;
  }

  @media (max-width: 364px) {
    font-size: 11px;
  }

  @media (max-width: 318px) {
    font-size: 10px;
  }
`;

export const ContactUsHyperLink = styled.a`
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-top: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 504px) {
    font-size: 16px;
  }

  @media (max-width: 412px) {
    font-size: 14px;
  }

  @media (max-width: 364px) {
    font-size: 12px;
  }

  @media (max-width: 318px) {
    font-size: 10px;
  }
`;
