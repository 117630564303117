/*eslint-disable*/
import styled from "styled-components";
import firebasesvg from "../assets/images/firebase.svg";
import reactsvg from "../assets/images/react.svg";
import javascriptsvg from "../assets/images/javascript.svg";
import githubpng from "../assets/images/github.png";
import htmlsvg from "../assets/images/html.svg";
import csssvg from "../assets/images/css.svg";
import apachepng from "../assets/images/apache.png";
import zzunihomelabjpeg from "../assets/images/zzunihomelab.jpeg";

export const ModalBackground = styled.div`
  background-color: rgb(255, 255, 255, 0.9);
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
  padding: 0;
  margin: 0;
  z-index: 20;
`;

export const ModalTitleText = styled.p`
  font-size: 34px;
  font-weight: 600;
`;

export const ModalSubTitleText = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-top: 15px;
`;

export const ModalDescText = styled.p`
  font-size: 17px;
  font-weight: 400;
  margin-top: 10px;
  color: #5f5f5f;
`;

export const ModalTechStackContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center;
`;

export const ModalTechStackUl = styled.ul``;

export const ModalTechStackLi = styled.li``;

export const ModalTechStackItemFirebase = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${firebasesvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ModalTechStackItemReact = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${reactsvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ModalTechStackItemJavascript = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${javascriptsvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ModalTechStackItemHtml = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${htmlsvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ModalTechStackItemCss = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${csssvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ModalTechStackItemApache = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${apachepng});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ModalReferenceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const ModalReferencezzuniHomelabImage = styled.div`
  width: 500px;
  height: 300px;
  background-image: url(${zzunihomelabjpeg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const GithubIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${githubpng});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-top: 10px;
`;

export const InfoText = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  color: #ff5555;
`;
