/* eslint-disable*/
import React from "react";
import * as s from "../styles/ModalStyle";

const redirectToGithub = () => {
  location.href = "https://github.com/zzunipark/zzuniMirror";
};

const FanfanTVModal = ({ onClose }) => {
  return (
    <s.ModalBackground>
      <s.ModalContainer>
        <s.ModalTitleText>zzuniMirror</s.ModalTitleText>
        <s.InfoText>해당 프로젝트는 임시중단된 프로젝트입니다.</s.InfoText>
        <s.ModalCloseButton onClick={onClose}>X</s.ModalCloseButton>
        <s.ModalDescText>
          zzuniMirror는 2023년 10월에 시작된 프로젝트로, 오픈 소스 소프트웨어를 미러링하고 아카이빙하는 서비스를 제공하는 프로젝트입니다.
          <br />
          zzuniMirror는 http로 접근할 수 있으며, Ubuntu, Debian, Opnsense 등 다양한 오픈 소스 소프트웨어를 미러링하고 있습니다.
          <br />
          zzuniHomelab 프로젝트와 연계하여, 6TiB 스토리지를 보유한 서버를 자체구축하여 서비스를 제공하고 있었으나, 현재는 서비스 제공을 일시중단하였습니다.
        </s.ModalDescText>
        <s.ModalSubTitleText>기술 스택</s.ModalSubTitleText>
        <s.ModalTechStackContainer>
          <s.ModalTechStackItemHtml />
          <s.ModalTechStackItemCss />
          <s.ModalTechStackItemApache />
        </s.ModalTechStackContainer>
        <s.ModalSubTitleText>참조</s.ModalSubTitleText>
        <s.ModalDescText>
          자유 소프트웨어를 이용해 운영됨.
          <br />
          Rsync와 Cron을 이용해 6시간 단위로 미러링을 진행함.
          <br />
          Ubuntu, Ubuntu-CD, Ubuntu-CDImage, Debian, Raspbian, Opnsense, Apache 등의 오픈 소프트웨어 미러링을 진행함.
        </s.ModalDescText>
        <s.GithubIcon onClick={redirectToGithub} />
      </s.ModalContainer>
    </s.ModalBackground>
  );
};

export default FanfanTVModal;
