/* eslint-disable*/
import React from "react";
import * as s from "../styles/ModalStyle";

const FanfanTVModal = ({ onClose }) => {
  return (
    <s.ModalBackground>
      <s.ModalContainer>
        <s.ModalTitleText>FANFANTV</s.ModalTitleText>
        <s.InfoText>해당 프로젝트의 깃허브 레포지토리는 비공개 상태로, 방문하실 수 없습니다.</s.InfoText>
        <s.ModalCloseButton onClick={onClose}>X</s.ModalCloseButton>
        <s.ModalDescText>
          FANFANTV는 2023년 6월부터 시작된 프로젝트로, 오직 1학년 3반만을 위해 제작되었습니다.
          <br />
          FANFANTV에서는 다양한 형식의 이미지 파일을 업로드하면 전체 갤러리에서 업로드된 이미지를 확인할 수 있습니다.
          <br />
          이를 통해 다양한 1학년 3반만의 개성있는 짤들을 공유하고, 이를 통해 1학년 3반의 유대감을 높이는 것을 목표로 하고 있습니다.
          <br />
          2023년 6월 프로토타입이 제작된 이후로 꾸준히 개발되어 알파 및베타테스트를 거친 후 현재는 정식 릴리즈 버전을 배포하여 운영중입니다.
        </s.ModalDescText>
        <s.ModalSubTitleText>기술 스택</s.ModalSubTitleText>
        <s.ModalTechStackContainer>
          <s.ModalTechStackItemFirebase />
          <s.ModalTechStackItemReact />
          <s.ModalTechStackItemJavascript />
        </s.ModalTechStackContainer>
        <s.ModalSubTitleText>참조</s.ModalSubTitleText>
        <s.ModalDescText>
          사용된 Firebase Build 목록
          <br />- Firebase Hosting
          <br />- Firebase Authentication
          <br />- Firebase Cloud Storage
          <br />
          <br />
          <a href="https://www.fanfantv.online/">{"FANFANTV를 방문하시려면 여기를 클릭하세요."}</a>
        </s.ModalDescText>
        <s.GithubIcon />
      </s.ModalContainer>
    </s.ModalBackground>
  );
};

export default FanfanTVModal;
