import GlobalStyle from "./components/public/GlobalStyle";
import MainPage from "./components/pages/MainPage";

function App() {
  return (
    <>
      <GlobalStyle />
      <MainPage />
    </>
  );
}

export default App;
