/*eslint-disable*/
import React, { useState, useEffect } from "react";
import * as s from "../styles/MainPageStyle";
import BackgroundVideo from "../assets/videos/background.mp4";
import FanfanTVModal from "../components/fanfantvmodal";
import ZzuniMirrorModal from "../components/zzunimirrormodal";
import ZzuniHomelabModal from "../components/zzunihomelabmodal";

const MainPage = () => {
  const [language, setLanguage] = useState("Korean");
  const [displayedText, setDisplayedText] = useState("");
  const AboutUsRef = React.createRef();
  const ProjectsRef = React.createRef();
  const ContactUsRef = React.createRef();
  const [showFanfanTVModal, setShowFanfanTVModal] = useState(false);
  const [showZzuniMirrorModal, setShowZzuniMirrorModal] = useState(false);
  const [showZzuniHomelabModal, setShowZzuniHomelabModal] = useState(false);

  const openFanfanTVModal = () => {
    setShowFanfanTVModal(true);
  };

  const openZzuniMirrorModal = () => {
    setShowZzuniMirrorModal(true);
  };

  const openZzuniHomelabModal = () => {
    setShowZzuniHomelabModal(true);
  };

  useEffect(() => {
    const targetText = "zzuniLabs";
    let currentIndex = 0;

    const typingInterval = setInterval(() => {
      setDisplayedText(targetText.slice(0, currentIndex + 1));

      if (currentIndex === targetText.length - 1) {
        clearInterval(typingInterval);
      }

      currentIndex += 1;
    }, 150);

    return () => clearInterval(typingInterval);
  }, []);

  const goToStatus = () => {
    location.href = "https://zzunilabs.statuspage.io/";
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "Korean" ? "English" : "Korean"));
  };

  const handleVideoClick = (e) => {
    e.currentTarget.paused ? e.currentTarget.play() : e.currentTarget.pause();
  };

  return (
    <>
      {language === "Korean" ? (
        <>
          <s.MainNavbar>
            <s.LogoBox />
            <s.Navigator>
              <s.Navigator>
                <s.NavigatorText onClick={() => AboutUsRef.current.scrollIntoView({ behavior: "smooth" })}>소개</s.NavigatorText>
                <s.NavigatorText onClick={() => ProjectsRef.current.scrollIntoView({ behavior: "smooth" })}>프로젝트</s.NavigatorText>
                <s.NavigatorText onClick={() => ContactUsRef.current.scrollIntoView({ behavior: "smooth" })}>연락처</s.NavigatorText>
              </s.Navigator>
            </s.Navigator>
            <s.UtilityBox>
              <s.Status onClick={goToStatus} />
              <s.Globe onClick={toggleLanguage} />
            </s.UtilityBox>
          </s.MainNavbar>
          <s.MainContainer>
            <video controls={false} muted={true} autoPlay={true} loop={true} onClick={handleVideoClick} playsInline>
              <source src={BackgroundVideo} type="video/mp4" />
              귀하의 브라우저는 영상 재생 기능을 지원하지 않습니다. 다른 브라우저로 시도하세요.
            </video>
            <s.MainTitleText>{displayedText}</s.MainTitleText>
            <s.MainDescText>아이디어의 믿음과 혁신의 역량이 만나, 쭈니랩스는 항상 새로운 가치를 도출해냅니다.</s.MainDescText>
          </s.MainContainer>
          <s.SubContainer1 ref={AboutUsRef}>
            <s.SubContainerSection>
              <s.SubContainerSectionTextBox>
                <s.SubContainerSectionTitle1>소개</s.SubContainerSectionTitle1>
                <s.SubContainerSectionTitle2>zzuniLabs를 소개합니다.</s.SubContainerSectionTitle2>
                <s.SubContainerSectionDesc>
                  쭈니랩스는 상상만 해오던 아이디어를 현실로 만들어내기 위해 믿음을 갖고 활동합니다. 새로운 가능성에 대한 확고한 믿음을 바탕으로 혁신적인 프로젝트를 추진하고 있으며, 이를 통해 상상에서 시작한 아이디어를 실제로 실현하는 과정을 밟아나가며 끊임없이 발전하고 있습니다.
                </s.SubContainerSectionDesc>
                <s.SubContainerButton>프로젝트 목록 확인하기</s.SubContainerButton>
              </s.SubContainerSectionTextBox>
              <s.SubContainerSectionImageBox>
                <s.IncreaseImageBox />
              </s.SubContainerSectionImageBox>
            </s.SubContainerSection>
          </s.SubContainer1>
          <s.SubContainer2 ref={ProjectsRef}>
            <s.SubContainerSectionTextBox2>
              <s.SubContainer2SectionTitle>프로젝트 목록</s.SubContainer2SectionTitle>
              <s.SubContainer2SectionTitle2>zzuniLabs의 프로젝트</s.SubContainer2SectionTitle2>
              <s.SubContainer2SectionDesc>
                다양한 프로젝트들이 쭈니랩스를 구성하고 있습니다.
                <br />
                자세히 알아보려면 박스를 클릭하세요.
              </s.SubContainer2SectionDesc>
            </s.SubContainerSectionTextBox2>
            <s.ProjectGrid>
              <s.ProjectCardContainer onClick={openFanfanTVModal}>
                <s.ProjectTitle>FANFANTV</s.ProjectTitle>
                <s.ProjectDescription>광주소프트웨어마이스터고등학교 1학년 3반 짤 관리 시스템</s.ProjectDescription>
                <s.OperationalText>운영중</s.OperationalText>
                <s.GotoProjectButton onClick={() => (window.location.href = "https://www.fanfantv.online/")}>바로가기</s.GotoProjectButton>
              </s.ProjectCardContainer>
              <s.ProjectCardContainer onClick={openZzuniMirrorModal}>
                <s.ProjectTitle>zzuniMirror</s.ProjectTitle>
                <s.ProjectDescription>오픈 소스 소프트웨어 미러링 & 아카이빙 서비스</s.ProjectDescription>
                <s.MaintenanceText>임시중단</s.MaintenanceText>
              </s.ProjectCardContainer>
              <s.ProjectCardContainer onClick={openZzuniHomelabModal}>
                <s.ProjectTitle>zzuniHomelab</s.ProjectTitle>
                <s.ProjectDescription>가정에서 구축하는 서버 & 네트워크 시스템</s.ProjectDescription>
                <s.MaintenanceText>진행중</s.MaintenanceText>
              </s.ProjectCardContainer>
            </s.ProjectGrid>
          </s.SubContainer2>
          <s.SubContainer3 ref={ContactUsRef}>
            <s.ContactUsFormBox>
              <s.ContactUsFormTitleText>궁금한점이 있으신가요?</s.ContactUsFormTitleText>
              <s.ContactUsFormDescText>
                me@zzunipark.com으로 직접 메일을 보내주시거나 <s.ContactUsHyperLink href="mailto:me@zzunipark.com?subject=zzuniLabs에 관한 문의사항이 있습니다.&body=__ zzuniLabs에 관한 문의사항을 하단에 작성해주세요. __">여기</s.ContactUsHyperLink>를 눌러 양식에 맞춰 상세히 문의하실 수 있습니다.
              </s.ContactUsFormDescText>
            </s.ContactUsFormBox>
          </s.SubContainer3>

          {showFanfanTVModal && <FanfanTVModal onClose={() => setShowFanfanTVModal(false)} />}
          {showZzuniMirrorModal && <ZzuniMirrorModal onClose={() => setShowZzuniMirrorModal(false)} />}
          {showZzuniHomelabModal && <ZzuniHomelabModal onClose={() => setShowZzuniHomelabModal(false)} />}
        </>
      ) : (
        <>
          <s.MainNavbar>
            <s.LogoBox />
            <s.Navigator>
              <s.Navigator>
                <s.NavigatorText onClick={() => AboutUsRef.current.scrollIntoView({ behavior: "smooth" })}>About Us</s.NavigatorText>
                <s.NavigatorText onClick={() => ProjectsRef.current.scrollIntoView({ behavior: "smooth" })}>Projects</s.NavigatorText>
                <s.NavigatorText>Contact Us</s.NavigatorText>
              </s.Navigator>
            </s.Navigator>
            <s.UtilityBox>
              <s.Status />
              <s.Globe onClick={toggleLanguage} />
            </s.UtilityBox>
          </s.MainNavbar>
          <s.MainContainer>
            <video controls={false} muted={true} autoPlay={true} loop={true} onClick={handleVideoClick} playsInline>
              <source src={BackgroundVideo} type="video/mp4" />
              Unfortunately, your browser doesn't have the capability to play videos. Try other browsers.
            </video>
            <s.MainTitleText>{displayedText}</s.MainTitleText>
            <s.MainDescTexteng>zzuniLabs always create new values with the belief in ideas and the ability of innovation.</s.MainDescTexteng>
          </s.MainContainer>
          <s.SubContainer1 ref={AboutUsRef}>
            <s.SubContainerSectioneng>
              <s.SubContainerSectionTextBox>
                <s.SubContainerSectionTitle1>About Us</s.SubContainerSectionTitle1>
                <s.SubContainerSectionTitle2>About zzuniLabs.</s.SubContainerSectionTitle2>
                <s.SubContainerSectionDesc>
                  zzuniLabs operates with a belief in turning ideas that were once only imagined into reality. With a strong conviction in the potential of new possibilities, we drive innovative projects forward. Through this, we continually advance by taking the journey from conceptualizing ideas
                  to actualizing them, firmly rooted in the belief that imagination is the starting point for bringing ideas to life.
                </s.SubContainerSectionDesc>
                <s.SubContainerButtoneng>Go to Check Projects</s.SubContainerButtoneng>
              </s.SubContainerSectionTextBox>
              <s.SubContainerSectionImageBox>
                <s.IncreaseImageBox />
              </s.SubContainerSectionImageBox>
            </s.SubContainerSectioneng>
          </s.SubContainer1>
          <s.SubContainer2 ref={ProjectsRef}>
            <s.SubContainerSectionTextBox2>
              <s.SubContainer2SectionTitle>Projects</s.SubContainer2SectionTitle>
              <s.SubContainer2SectionTitle2>zzuniLabs' Projects</s.SubContainer2SectionTitle2>
              <s.SubContainer2SectionDesceng>A variety of projects make up zzuniLabs.</s.SubContainer2SectionDesceng>
            </s.SubContainerSectionTextBox2>
            <s.ProjectGrid>
              <s.ProjectCardContainer>
                <s.ProjectTitle>FANFANTV</s.ProjectTitle>
                <s.ProjectDescriptionlongeng>Gwangju Software Meister High School 1-3 Meme Management System</s.ProjectDescriptionlongeng>
                <s.OperationalText>Operational</s.OperationalText>
                <s.GotoProjectButton onClick={() => (window.location.href = "https://www.fanfantv.online/")}>Visit</s.GotoProjectButton>
              </s.ProjectCardContainer>
              <s.ProjectCardContainer>
                <s.ProjectTitle>zzuniMirror</s.ProjectTitle>
                <s.ProjectDescription>Open Source Software Mirroring & Archiving Service</s.ProjectDescription>
                <s.MaintenanceText>Temporary Closed</s.MaintenanceText>
              </s.ProjectCardContainer>
              <s.ProjectCardContainer>
                <s.ProjectTitle>zzuniHomelab</s.ProjectTitle>
                <s.ProjectDescription>Home-Based Server & Network System {"(Homelab)"}</s.ProjectDescription>
                <s.MaintenanceText>In Progress</s.MaintenanceText>
              </s.ProjectCardContainer>
            </s.ProjectGrid>
          </s.SubContainer2>
          <s.SubContainer3 ref={ContactUsRef}>
            <s.ContactUsFormBox>
              <s.ContactUsFormTitleTexteng>Do you have any inquiries?</s.ContactUsFormTitleTexteng>
              <s.ContactUsFormDescTexteng>
                Please send an email to me@zzunipark.com or click <s.ContactUsHyperLink href="mailto:me@zzunipark.com?subject=Inquiry%20about%20zzuniLabs&body=__Please%20fill%20out%20the%20details%20regarding%20your%20inquiry%20about%20zzuniLabs%20below.__">here</s.ContactUsHyperLink> to fill out a
                form with the specific details about your inquiry.
              </s.ContactUsFormDescTexteng>
            </s.ContactUsFormBox>
          </s.SubContainer3>
        </>
      )}
    </>
  );
};

export default MainPage;
